import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = [
    'likelihoodSelect',
    'likelihoodSelectOpposite',
    'irremediabilitySelect',
    'irremediabilitySelectOpposite',
    'positiveOrNegativeOpposite',
    'positiveOrNegative'
  ];

  connect() {
    // Set the default state of the likelihood select elements
    this.toggleLikelihood(this.likelihoodSelectTarget.value, 'matter_evaluation');
    if (this.hasLikelihoodSelectOppositeTarget) {
      this.toggleLikelihood(this.likelihoodSelectOppositeTarget.value, 'opposite_matter_evaluation');
    }

    // Set the default state of the irremediability select elements
    const positiveOrNegative = this.positiveOrNegativeTarget.value;
    this.toggleIrremediability(positiveOrNegative);
  }

  likelihoodOnChange(event) {
    const value = event.target.value;
    const objectType = event.target.dataset.objectType;
    this.toggleLikelihood(value, objectType);
  }

  toggleLikelihood(value, objectType) {
    const likelihoodTarget =
      objectType === 'matter_evaluation'
        ? this.likelihoodSelectTarget
        : this.likelihoodSelectOppositeTarget;
    const select = likelihoodTarget.querySelector('select');

    if (value === 'potential') {
      likelihoodTarget.classList.remove('hidden');
      select.required = true;
    } else {
      likelihoodTarget.classList.add('hidden');
      select.required = false;
    }
  }

  syncPositiveOrNegative(event) {
    if (this.hasPositiveOrNegativeOppositeTarget) {
      const value = event.target.value;
      const oppositeValue = value === 'positive' ? 'negative' : 'positive';
      const selectElement = this.positiveOrNegativeOppositeTarget;

      selectElement.value = oppositeValue;
    }

    this.syncIrremediability(event);
  }

  syncIrremediability(event) {
    const value = event.target.value;
    this.toggleIrremediability(value);
  }

  toggleIrremediability(value) {
    // This is only triggered by the matter_evaluation form and not the opposite form
    if (value === 'positive') {
      const select = this.irremediabilitySelectTarget.querySelector('select');
      this.irremediabilitySelectTarget.classList.add('hidden');
      select.required = false;

      if (this.hasIrremediabilitySelectOppositeTarget) {
        const opposite_select = this.irremediabilitySelectOppositeTarget.querySelector('select');
        this.irremediabilitySelectOppositeTarget.classList.remove('hidden');
        opposite_select.required = true;
      }
    } else {
      const select = this.irremediabilitySelectTarget.querySelector('select');
      this.irremediabilitySelectTarget.classList.remove('hidden');
      select.required = true;

      if (this.hasIrremediabilitySelectOppositeTarget) {
        const opposite_select = this.irremediabilitySelectOppositeTarget.querySelector('select');
        this.irremediabilitySelectOppositeTarget.classList.add('hidden');
        opposite_select.required = false;
      }
    }
  }
}
