import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  connect() {
    this.isDirty = false;
    this.redirectAfterSubmit = null;

    this.element.addEventListener('change', () => {
      this.isDirty = true;
    });

    this.element.addEventListener('submit', () => {
      this.isDirty = false;
    });

    this.element.addEventListener('turbo:submit-end', () => {
      if (this.redirectAfterSubmit) {
        window.location.href = this.redirectAfterSubmit;
      }
    });

    window.addEventListener('beforeunload', this._handleBeforeUnload);

    document.querySelectorAll('a').forEach((link) => {
      link.addEventListener('click', this._handleLinkClick.bind(this));
    });
  }

  disconnect() {
    window.removeEventListener('beforeunload', this._handleBeforeUnload);
  }

  _handleBeforeUnload = (e) => {
    if (this.isDirty) {
      e.preventDefault();
      e.returnValue = '';
    }
  };

  _handleLinkClick(e) {
    if (
      this.isDirty &&
      !e.currentTarget.closest('form') &&
      !e.currentTarget.classList.contains('button-delete')
    ) {
      e.preventDefault();

      const confirmSave = confirm(
        'There are unsaved changes. Click OK to continue and save the changes, or Cancel to stop the action.'
      );
      if (confirmSave) {
        window.removeEventListener('beforeunload', this._handleBeforeUnload);
        this.redirectAfterSubmit = e.currentTarget.href;
        this.element.requestSubmit();
      }
    }
  }
}
